import { Link, NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as RealIcon } from "../../assets/icons/sidebar/real.svg";
import { ReactComponent as PropIcon } from "../../assets/icons/sidebar/allProperties.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/sidebar/email.svg";
import { ReactComponent as CompIcon } from "../../assets/icons/sidebar/company.svg";
import { ReactComponent as ManageIcon } from "../../assets/icons/sidebar/manageProp.svg";
import { ReactComponent as BunIcon } from "../../assets/icons/sidebar/propIcon.svg";
import { ReactComponent as FavIcon } from "../../assets/icons/sidebar/fav.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/sidebar/Profile.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/sidebar/logout.svg";
import { ReactComponent as LoginIcon } from "../../assets/icons/sidebar/loginIcon.svg";
import dropdownArr from "../../assets/icons/down-heder-arr.svg";

import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from "../../data/slices/settingsSlice";
import { useTranslation } from "react-i18next";
import { logout } from "../../data/slices/authSlice";

import cookies from "js-cookie";
import i18next from "i18next";
import LangBtn from "../LangBtn/LangBtn";
import { useState } from "react";

const NavigationLink = ({ icon = null, route = "/", children }) => {
  const dispatch = useDispatch();

  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <>
      <NavLink
        to={route}
        className="navigation_link"
        onClick={() => dispatch(closeSidebar())}
      >
        {icon}
        <span className="navigation_link__label">{children}</span>
      </NavLink>
      <div className="divider"></div>
    </>
  );
};

const Navigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentLanguageCode = cookies.get("i18next") || "en";

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  return (
    <div className="navigation__container">
      {currentLanguageCode === "en" && (
        <h3 className="navigation__container-title">
          {t("services-list.services-title")}
        </h3>
      )}

      <NavigationLink route="/">{t("header.home")}</NavigationLink>
      <NavigationLink route="/about-us">{t("header.about-us")}</NavigationLink>
      <div className="header-dropdown">
        <button className="header-dropbtn" onClick={toggleDropdown}>
          {t("header.offers")}
          <img src={dropdownArr} alt="dropdownArr" />
        </button>
        <div
          className={
            isDropdownOpen
              ? currentLanguageCode === "en"
                ? "header-dropdown-content"
                : "header-dropdown-content_ar"
              : "header-dropdown-hidden" // Add a hidden class to hide the dropdown
          }
        >
          <div className="divider"></div>
          <Link to="/?service=1" onClick={() => dispatch(closeSidebar())}>
            {t("header.service1")}
          </Link>
          <div className="divider"></div>
          <Link to="/?service=2" onClick={() => dispatch(closeSidebar())}>
            {t("header.service2")}
          </Link>
          <div className="divider"></div>
          <Link to="/?service=3" onClick={() => dispatch(closeSidebar())}>
            {t("header.service3")}
          </Link>
          <div className="divider"></div>
          <Link to="/?service=4" onClick={() => dispatch(closeSidebar())}>
            {t("header.service4")}
          </Link>
        </div>
      </div>
      <div className="divider"></div>
      <LangBtn />
    </div>
  );
};

export default Navigation;
