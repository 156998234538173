import React, { useState } from "react";
import "./_SavingMoney.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as MailIcon } from "../../../../assets/icons/landing/mail.svg";
import { emailInquiry } from "../../../../data/slices/contactSlice";
import { useDispatch } from "react-redux";
import { message } from "antd";

function SavingMoneySection() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [validEmail, setValidEmail] = useState(true); // State to track email validity

  const handleChange = (e) => {
    setMail(e.target.value);
    // Validate email on change
    setValidEmail(e.target.checkValidity());
  };

  const handleEmailInquiry = () => {
    // Check if email is valid before dispatching
    if (validEmail) {
      dispatch(emailInquiry({ email: mail, lang: i18n?.language }))
        .unwrap()
        .then(
          (data) =>
            data?.error === 0 && message.success(data?.message) && setMail("")
        )
        .catch((err) => console.log(err));
    } else {
      // Show error message if email is not valid
      message.error(t("landing.invalid_email"));
    }
  };

  return (
    <div className="landing-footer">
      <div className="landing-footer-container">
        <h1 className="title">{t("landing.get_a_tour_of_your_property")}</h1>
        <p className="subtitle">
          {t("landing.contact_us_for_the_best_offers")}
        </p>
        <div className="input-container">
          <MailIcon />
          <input
            type="email"
            placeholder={t("landing.send_us_your_email")}
            onChange={handleChange}
            value={mail}
            // Add required attribute for HTML5 validation
            required
            // Add pattern attribute for email validation
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          />
          <button className="register-btn" onClick={handleEmailInquiry}>
            {t("landing.register")}
          </button>
        </div>
        {/* Display error message if email is not valid */}
        {/* {!validEmail && (
          <p className="error-message">{t("landing.invalid_email")}</p>
        )} */}
      </div>
    </div>
  );
}

export default SavingMoneySection;
