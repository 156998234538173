import React, { useEffect, useState } from "react";
import ImagesCustomSwiper from "../Swiper/Swiper";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../../data/slices/propertiesSlice";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "./_LandingImages.scss";
import { HashScroll } from "react-hash-scroll";

function LandingImagesSliding({ home_data, setSelectedServiceId }) {
  const { t, i18n } = useTranslation();

  return (
    <HashScroll hash="#result">
      <div className="more-container">
        {home_data && home_data.length > 0 && (
          <h3>
            {t("landing.leading_in_producing_virtual_tours_of_real_estate")}
          </h3>
        )}
        <div className="more-swiper">
          {home_data && home_data.length > 0 ? (
            <ImagesCustomSwiper
              items={home_data}
              setPreviewImg={setSelectedServiceId}
            />
          ) : (
            <></>
            // <Spin />
          )}
        </div>
      </div>
    </HashScroll>
  );
}

export default LandingImagesSliding;
