import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import cookies from "js-cookie";

const lang = cookies.get("i18next") || "en";

const initialState = {
  properties: [],
  home_data: [],
  property_types: [],
  property: null,
  specifications: [],
  filters: null,
  default_values: [],
  error: null,
  message: null,
  loading: "idle",
  currency_icon: null,
  currency_key: "",
  property_types_loading: "idle",
};

export const getServices = createAsyncThunk(
  "/home/get_services",
  async ({ lang }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/home/get_services?lang=${lang}`
      );
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return rejectWithValue(err.message || "Something went wrong");
    }
  }
);

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    setSpecifications(state, action) {
      state.specifications = action.payload;
    },
    setDefaultValues(state, action) {
      state.default_values = action.payload;
    },
    setProperties(state, action) {
      state.properties = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.loading = "idle";
        state.home_data = action.payload.service; // Ensure we're storing the correct data
      })
      .addCase(getServices.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.payload;
      });
  },
});

export const { setSpecifications, setDefaultValues, setProperties } =
  propertiesSlice.actions;

export default propertiesSlice.reducer;
