import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../../data/slices/propertiesSlice";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "./_LandingServicesContents.scss";

import specs from "../../../../assets/icons/specs-vector.svg";
import phone from "../../../../assets/icons/phone-vector.svg";
import verified from "../../../../assets/icons/verified-vector.svg";
import users from "../../../../assets/icons/users-vector.svg";

import times from "../../../../assets/icons/times-vector.svg";
import plus from "../../../../assets/icons/plus-vector.svg";

import map from "../../../../assets/icons/map-vector.svg";
import moneyBoard from "../../../../assets/icons/money-board-vector.svg";

import imageSearch from "../../../../assets/icons/image-finder-vector.svg";
import axiosInstance from "../../../../utils/axios";

const LandingServicesContents = ({ service_id }) => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  const { recent, type_list } = useSelector(
    (state) => state.properties.home_data
  );
  const dispatch = useDispatch();

  // useEffect(() => {
  //   axiosInstance.get("")
  // }, [dispatch, i18n?.language, currentLanguageCode, service_id]);

  const renderContent = () => {
    if (service_id === "1") {
      return (
        <>
          <h3>{t("services-list.service1")}</h3>
          <div className="content-list">
            <div className="content-card blue-bg">
              <div className="inner-contents">
                <div className="vector">
                  <img src={specs} alt="specs" />
                </div>
                <p>{t("services1-content.content1-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={phone} alt="phone" />
                </div>
                <p>{t("services1-content.content2-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={verified} alt="verified" />
                </div>
                <p>{t("services1-content.content3-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={users} alt="users" />
                </div>
                <p>{t("services1-content.content4-description")}</p>
              </div>
            </div>
          </div>
        </>
      );
    } else if (service_id === "2") {
      // Render content for service_id 2
      return (
        <>
          <h3>{t("services-list.service2")}</h3>
          <div className="content-list">
            <div className="content-card blue-bg">
              <div className="inner-contents">
                <div className="vector">
                  <img src={specs} alt="specs" />
                </div>
                <p>{t("services2-content.content1-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={times} alt="times" />
                </div>
                <p>{t("services2-content.content2-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={plus} alt="plus" />
                </div>
                <p>{t("services2-content.content3-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={users} alt="users" />
                </div>
                <p>{t("services2-content.content4-description")}</p>
              </div>
            </div>
          </div>
        </>
      );
    } else if (service_id === "3") {
      // Render content for service_id 3
      return (
        <>
          <h3>{t("services-list.service3")}</h3>
          <div className="content-list">
            <div className="content-card blue-bg">
              <div className="inner-contents">
                <div className="vector">
                  <img src={map} alt="map" />
                </div>
                <p>{t("services3-content.content1-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={moneyBoard} alt="moneyBoard" />
                </div>
                <p>{t("services3-content.content2-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={verified} alt="plus" />
                </div>
                <p>{t("services3-content.content3-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={users} alt="users" />
                </div>
                <p>{t("services3-content.content4-description")}</p>
              </div>
            </div>
          </div>
        </>
      );
    } else if (service_id === "4") {
      // Render content for service_id 4
      return (
        <>
          <h3>{t("services-list.service4")}</h3>
          <div className="content-list">
            <div className="content-card blue-bg">
              <div className="inner-contents">
                <div className="vector">
                  <img src={imageSearch} alt="imageSearch" />
                </div>
                <p>{t("services4-content.content1-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={map} alt="map" />
                </div>
                <p>{t("services4-content.content2-description")}</p>
              </div>
            </div>
            <div className="content-card">
              <div className="inner-contents">
                <div className="vector">
                  <img src={users} alt="users" />
                </div>
                <p>{t("services4-content.content3-description")}</p>
              </div>
            </div>{" "}
          </div>
        </>
      );
    }
    return null; // Default case if no service_id matches
  };

  return <div className="more-container">{renderContent()}</div>;
};

export default LandingServicesContents;
