import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../data/slices/settingsSlice";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu_white.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/CloseIcon.svg";
import useWindowSize from "../../hooks/useWindowSize";

const HamburgerMenuTrigger = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const isSidebarOpened = useSelector(
    (state) => state.settings.isSidebarOpened
  );

  if (width >= 1090) return null;

  return (
    <div
      className="hamburger__bouton"
      onClick={() => dispatch(toggleSidebar())}
    >
      {isSidebarOpened ? <CloseIcon /> : <MenuIcon />}
    </div>
  );
};

export default HamburgerMenuTrigger;
