import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../data/slices/modals";
import { ModalExample } from "../Modals";
import { setProperties } from "../../data/slices/propertiesSlice";
import { useLocation } from "react-router-dom";

const ModalsProvider = (props) => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };

  const location = useLocation();

  useEffect(() => {
    console.log("running");
    dispatch(setProperties([]));
  }, [location.pathname]);

  return (
    <>
      <ModalExample
        id="modal-example"
        open={modalState("modal-example", "open")}
        data={modalState("modal-example", "data")}
        handleClose={handleClose}
      />
    </>
  );
};

export default ModalsProvider;
