import { BrowserRouter, Routes, Route } from "react-router-dom";
import ModalsProvider from "./components/ModalsProvider";
import MainLayout from "./layouts/MainLayout";
import NotFoundView from "./components/NotFound";

import { lazy, useEffect } from "react";
import { Suspense } from "react";
import Spinner from "./components/Spinner/Spinner";
import Landing from "./features/Landing/Landing";

const RoutesProvider = () => {
  const ContactView = lazy(() => import("./views/Contact"));

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Routes>
          {/* Users Routes */}
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Landing />} />
            <Route path="about-us" element={<ContactView />} />
          </Route>
          {/* Uncreated Routes */}
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </Suspense>
      <ModalsProvider />
    </BrowserRouter>
  );
};

export default RoutesProvider;
