import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../../data/slices/propertiesSlice";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "./_LandingServicesResultsSliding.scss";
import ImagesCustomSwiper2 from "../Swiper/Swiper-results";
import axiosInstance from "../../../../utils/axios";
import { HashScroll } from "react-hash-scroll";

function LandingServicesResultsSliding({ serviceId }) {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    if (serviceId) {
      axiosInstance
        .get(
          `https://admin.jawlah.com.sa/api/home/get_projects?service_id=${serviceId}`
        )
        .then((response) => {
          setProjectData(response.data.project);
        })
        .catch((error) => {
          console.error("Error fetching project data:", error);
        });
    }
  }, [serviceId]);

  if (!projectData) return null;

  return (
    
      <div id="scroll-to-ref" className="more-container">
        {projectData && <h3>{t("landing.main-service-title-1")}</h3>}
        <div className="more-swiper">
          {projectData ? (
            <ImagesCustomSwiper2 items={projectData} />
          ) : (
            <></>
            // <Spin />
          )}
        </div>
      </div>
    
  );
}

export default LandingServicesResultsSliding;
