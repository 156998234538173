import { Tabs, Modal, Spin } from "antd";
import { ReactComponent as MenuIcon } from "../../assets/icons/landing/menu.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getProperties,
  // getpropertytypes,
} from "../../data/slices/propertiesSlice";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
import { handleStateChange } from "../../data/slices/filtersSlice";
import HeroVideo from "../../assets/videos/hero-video.mp4";
import LandingImagesSliding from "./components/LandingImageSliding/LandingImagesSliding";
import SavingMoneySection from "./components/SavingMoneySection/SavingMoneySection";
import { changeTab } from "../../data/slices/tabsSlice";
import LandingServicesResultsSliding from "./components/LandingServicesResultsSliding/LandingServicesResultsSliding";
import LandingServicesContents from "./components/LandingServicesContents/LandingServicesContents";
import publicAxios from "../../utils/publicAxios";
import { setServices } from "../../data/slices/servicesSlice";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

const Landing = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  

  const location = useLocation();
  const navigate = useNavigate();

  let queries = queryString.parse(location.search);

  console.log(location);

  const filters = useSelector((state) => state.filters);
  const { direction } = useSelector((state) => state.settings);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { currentTab } = useSelector((state) => state.tabs);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentLanguageCode = cookies.get("i18next") || "en";

  const { services } = useSelector((state) => state.services);

  console.log(services);

  const [projects, setProjects] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [defaultActiveKey, setDefaultActiveKey] = useState(
    getDefaultKey(currentTab)
  );

  useEffect(() => {
    if (queries.service) {
      setSelectedService(queries.service);
    }
  }, [queries.service]);

  useEffect(() => {
    const scrollRef = document.getElementById("scroll-to-ref")
    if (selectedService) {
      publicAxios
        .get(
          `/home/get_projects?service_id=${selectedService}&lang=${currentLanguageCode}`
        )
        .then((res) => {
          console.log(res);
          setProjects(res.data.project)
          
          navigate(`#result`)
        });
    }
  }, [currentLanguageCode, selectedService]);

  useEffect(()=>{
    if(selectedService){

      navigate(`/?service=${selectedService}`)
    }else{
      navigate("/")
    }
  },[location.hash])


  useEffect(() => {
    setDefaultActiveKey(getDefaultKey(currentTab));
  }, [currentTab]);

  function getDefaultKey(tab) {
    switch (tab) {
      case "Sale":
        return "0";
      case "Rent":
        return "1";
      case "Daily Rent":
        return "2";
      default:
        return "3";
    }
  }

  const getFiltersObject = (obj) => {
    const filteredObj = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && obj[key] !== "") {
        filteredObj[key] = obj[key];
      }
    });
    return filteredObj;
  };

  const handleBtnClick = (target, value) => {
    dispatch(
      handleStateChange({
        target,
        value,
      })
    );
    // dispatch(
    //   getProperties({
    //     lang: direction === "ltr" ? "en" : "ar",
    //     body: { offer_type: value },
    //   })
    // );
  };

  const onChange = (key) => {
    handleBtnClick(
      "offer_type",
      key === "0"
        ? "sell"
        : key === "1"
        ? "rent"
        : key === "2"
        ? "daily_rent"
        : "auction"
    );
    dispatch(
      changeTab(
        key === "0"
          ? "Sale"
          : key === "1"
          ? "Rent"
          : key === "2"
          ? "Daily Rent"
          : "Auction"
      )
    );
  };

  return (
    <div className="landing">
      <div className="content-container">
        <video
          width="750"
          height="500"
          autoPlay
          muted
          loop
          className="vr-toor-iframe"
        >
          <source src={HeroVideo} type="video/mp4" />
        </video>
        <div
          className={
            currentLanguageCode === "en"
              ? "content-card"
              : "content-card  content-card-ar"
          }
        >
          <h1 className="content-title">{t("services-list.services-title")}</h1>
          <div className="button-list">
            {services && services.map((item)=><button onClick={()=>setSelectedService(item.service_id)}>{item.service_name}</button>)}
            
          </div>
        </div>
      </div>

      <LandingImagesSliding home_data={services} setSelectedServiceId={setSelectedService} />

      <LandingServicesResultsSliding serviceId={selectedService} />

      <LandingServicesContents service_id={selectedService} />

      <SavingMoneySection />
    </div>
  );
};

export default Landing;
