import MenuHeader from "../Menu";
import HamburgerMenuTrigger from "../HamburgerMenuTrigger";
import useWindowSize from "../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { logout } from "../../data/slices/authSlice";
import Logo from "../Logo/Logo";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LangBtn from "../LangBtn/LangBtn";
import dropdownArr from "../../assets/icons/down-heder-arr.svg";
import englishLogo from "../../assets/icons/englishLogo.png";
import arabicLogo from "../../assets/icons/arabicLogo.png";

import cookies from "js-cookie";
import { useSelector } from "react-redux";

const Header = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const token = localStorage.getItem("aqari_token");
  const { services } = useSelector((state) => state.services);

  return (
    <>
      <header className="header">
        {width < 1091 && (
          <div className={"header_responsive"}>
            <div className="left_responsive">
              <HamburgerMenuTrigger />
            </div>
            <img
              className="header-mobile-logo"
              src={currentLanguageCode === "en" ? englishLogo : arabicLogo}
              alt=""
            />{" "}
          </div>
        )}
        {width > 1091 && (
          <>
            <Logo />
            <div className="header__right">
              <NavLink className="item_header link_header" to="/">
                {t("header.home")}
              </NavLink>

              <NavLink className="item_header link_header" to="/about-us">
                {t("header.about-us")}
              </NavLink>
              <div className="header-dropdown">
                <button className="header-dropbtn">
                  {t("header.offers")}
                  <img src={dropdownArr} alt="dropdownArr" />
                </button>
                <div
                  className={
                    currentLanguageCode === "en"
                      ? "header-dropdown-content"
                      : "header-dropdown-content_ar"
                  }
                >
                  {services &&
                    services.map((items) => {
                      return (
                        <Link to={`/?service=${items.service_id}`}>
                          {items.service_name}
                        </Link>
                      );
                    })}
                </div>
              </div>
              <LangBtn />
            </div>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
