import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import publicAxios from "../../utils/publicAxios";
import axiosInstance from "../../utils/axios";
import cookies from "js-cookie";

const lan = cookies.get("i18next") || "en";




export const serviceSlice = createSlice({
    name: "services",
    initialState: {
        services: null,
    },
    reducers: {
        setServices: (state, action) => {
            state.services = action?.payload;
        },

    },


});

export const { setServices } = serviceSlice.actions;

export default serviceSlice.reducer;
